// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.inner h1 {
  color: white;
}
.inner p {
  color: red;
  font-size: 1rem;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/loading-indicator/loading-indicator.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;AACJ;AACE;EACE,UAAA;EACA,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".inner {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n\n  h1 {\n    color: white;\n  }\n  p {\n    color: red;\n    font-size: 1rem;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
