// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td, th {
  border: 1px solid #ddd;
  padding: 8px;
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 10px;
}

th {
  text-align: left;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

tr:hover {
  background-color: #1080e1;
  color: white;
}

th label {
  display: inline-block; /* Ensures label contents are on one line */
  white-space: nowrap; /* Prevents text from wrapping */
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/activity-report-grid/activity-report-grid.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,6BAAA;EACA,eAAA;AACF;;AACA;EACE,gBAAA;EACA,mBAAA,EAAA,gCAAA;EACA,gBAAA;EACA,uBAAA;EACA,aAAA;EACA,6BAAA;AAEF;;AACA;EACE,yBAAA;EACA,YAAA;AAEF;;AACA;EACE,qBAAA,EAAA,2CAAA;EACA,mBAAA,EAAA,gCAAA;EACA,YAAA;AAEF","sourcesContent":["td,  th {\n  border: 1px solid #ddd;\n  padding: 8px;\n  padding: 12px; \n  text-align: left; \n  border-bottom: 1px solid #ddd;\n  font-size: 10px;\n}\nth {\n  text-align: left;\n  white-space: nowrap; /* Prevents text from wrapping */\n  overflow: hidden;\n  text-overflow: ellipsis;\n  padding: 12px;\n  border-bottom: 1px solid #ddd;\n}\n\ntr:hover { \n  background-color: #1080e1; \n  color: white;\n}\n\nth label {\n  display: inline-block; /* Ensures label contents are on one line */\n  white-space: nowrap; /* Prevents text from wrapping */\n  color: black;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
