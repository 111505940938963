// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn-primary {
  background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.btn-primary:hover {
  background: linear-gradient(135deg, #0056b3, #003d7e); /* Darker gradient on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

.btn-primary:active {
  box-shadow: 0 2px #0056b3, 0 0 15px 3px rgba(0, 123, 255, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/components/return-to-delta-report-btn/return.to.delta.report.btn.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AACJ;;AACE;EACE,qDAAA,EAAA,wBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EAEA,iCAAA;EACA,eAAA;EACA,eAAA;EACA,2CAAA;AACJ;;AAEE;EACE,qDAAA,EAAA,6BAAA;EACA,2BAAA,EAAA,yBAAA;AACJ;;AAEE;EACE,8DAAA;AACJ","sourcesContent":[".btn-container {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 20px;\n  }\n  .btn-primary {\n    background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n   \n    font-family: 'Gotham', sans-serif;\n    font-size: 14px;\n    cursor: pointer;\n    transition: background 0.3s, transform 0.3s;\n  }\n  \n  .btn-primary:hover {\n    background: linear-gradient(135deg, #0056b3, #003d7e); /* Darker gradient on hover */\n    transform: translateY(-2px); /* Lift effect on hover */\n  }\n\n  .btn-primary:active {\n    box-shadow: 0 2px #0056b3, 0 0 15px 3px rgba(0, 123, 255, 0.7);\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
