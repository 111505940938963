// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel {
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #f5f7fa, #DBDCDD);
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 10px;
}

.wide-switch {
  width: 100%; /* Adjust the width to your preference */
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/activity-start-justification-type/activity-start-justification-type.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,yCAAA;EACA,qDAAA;AACJ;;AAEE;EACE,sBAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAUE;EACE,WAAA,EAAA,wCAAA;AAPJ","sourcesContent":[".panel {\n    border: 1px solid #ddd;\n    border-radius: 12px;\n    padding: 20px;\n    box-shadow: 0 6px 12px rgba(0,0,0,0.1);\n    background: linear-gradient(135deg, #f5f7fa, #DBDCDD);\n  }\n\n  td,  th {\n    border: 1px solid #ddd;\n    padding: 8px;\n    font-size: 10px;\n  }\n\n//   tr:hover {\n//     background-color: lightblue;\n//     color: white;\n//     a{\n//       color: white;\n//     }\n//   }\n\n  .wide-switch {\n    width: 100%; /* Adjust the width to your preference */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
