// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr:hover {
  background-color: #330aea;
  color: white;
}

td, th {
  border: 1px solid #ddd;
  padding: 5px;
  font-size: 10px;
}

.panel {
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #f5f7fa, #DBDCDD);
}

.btn-primary {
  background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.btn-primary:hover {
  background: linear-gradient(135deg, #0056b3, #003d7e); /* Darker gradient on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

.btn-primary:active {
  box-shadow: 0 2px #0056b3, 0 0 15px 3px rgba(0, 123, 255, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/projects-management/projects-management.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;AACF;;AACC;EACC,sBAAA;EACA,YAAA;EACA,eAAA;AAEF;;AACA;EACE,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,yCAAA;EACA,qDAAA;AAEF;;AACA;EACE,qDAAA,EAAA,wBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EAEA,iCAAA;EACA,eAAA;EACA,eAAA;EACA,2CAAA;AACF;;AAEA;EACE,qDAAA,EAAA,6BAAA;EACA,2BAAA,EAAA,yBAAA;AACF;;AAIA;EACE,8DAAA;AADF","sourcesContent":["tr:hover {\n  background-color: #330aea;\n  color: white;\n}\n td,  th {\n  border: 1px solid #ddd;\n  padding: 5px;\n  font-size: 10px;\n}\n\n.panel {\n  border: 1px solid #ddd;\n  border-radius: 12px;\n  padding: 20px;\n  box-shadow: 0 6px 12px rgba(0,0,0,0.1);\n  background: linear-gradient(135deg, #f5f7fa, #DBDCDD);\n}\n\n.btn-primary {\n  background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n \n  font-family: 'Gotham', sans-serif;\n  font-size: 14px;\n  cursor: pointer;\n  transition: background 0.3s, transform 0.3s;\n}\n\n.btn-primary:hover {\n  background: linear-gradient(135deg, #0056b3, #003d7e); /* Darker gradient on hover */\n  transform: translateY(-2px); /* Lift effect on hover */\n}\n\n\n\n.btn-primary:active {\n  box-shadow: 0 2px #0056b3, 0 0 15px 3px rgba(0, 123, 255, 0.7);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
