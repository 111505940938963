// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-bottom: 1em;
}

h2 {
  margin-bottom: 0;
}

h3 {
  margin: 0;
}

:host ::ng-deep .customDialog {
  background-color: white !important;
  width: 50vw !important;
  box-shadow: 0px 20px 20px rgba(216, 221, 230, 0.5019607843);
  border: 1px solid rgb(231, 231, 231);
}

.panel {
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #f5f7fa, #DBDCDD);
}

.btn-primary {
  background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.btn-primary:hover {
  background: linear-gradient(135deg, #0056b3, #003d7e); /* Darker gradient on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

.btn-primary:active {
  box-shadow: 0 2px #0056b3, 0 0 15px 3px rgba(0, 123, 255, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/team-communication/team-communication.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAIE;EACI,kCAAA;EACA,sBAAA;EACA,2DAAA;EACA,oCAAA;AADN;;AAKA;EACE,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,yCAAA;EACA,qDAAA;AAFF;;AAKA;EACE,qDAAA,EAAA,wBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EAEA,iCAAA;EACA,eAAA;EACA,eAAA;EACA,2CAAA;AAHF;;AAMA;EACE,qDAAA,EAAA,6BAAA;EACA,2BAAA,EAAA,yBAAA;AAHF;;AAMA;EACE,8DAAA;AAHF","sourcesContent":[".container {\n  margin-bottom: 1em\n}\n\nh2 {\n  margin-bottom: 0;\n}\n\nh3 {\n  margin: 0;\n}\n\n// DIALOG BOX\n:host {\n  ::ng-deep .customDialog {\n      background-color: white !important;\n      width: 50vw !important;\n      box-shadow: 0px 20px 20px #D8DDE680;\n      border: 1px solid rgb(231, 231, 231);\n  }\n}\n\n.panel {\n  border: 1px solid #ddd;\n  border-radius: 12px;\n  padding: 20px;\n  box-shadow: 0 6px 12px rgba(0,0,0,0.1);\n  background: linear-gradient(135deg, #f5f7fa, #DBDCDD);\n}\n\n.btn-primary {\n  background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n \n  font-family: 'Gotham', sans-serif;\n  font-size: 14px;\n  cursor: pointer;\n  transition: background 0.3s, transform 0.3s;\n}\n\n.btn-primary:hover {\n  background: linear-gradient(135deg, #0056b3, #003d7e); /* Darker gradient on hover */\n  transform: translateY(-2px); /* Lift effect on hover */\n}\n\n.btn-primary:active {\n  box-shadow: 0 2px #0056b3, 0 0 15px 3px rgba(0, 123, 255, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
