// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-size: 0.9em;
}

body .table .table-tbody > tr:nth-child(even) {
  background-color: white;
}

.highlight {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #330aea;
  color: white;
}
tr:hover a {
  color: white;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

.non-scrollable-table .table-wrapper {
  overflow-x: visible !important;
}`, "",{"version":3,"sources":["webpack://./src/components/activityAuditLog/activityAuditLogGrid/activity.audit.log.grid.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,uBAAA;AAEF;;AAAA;EACE,yBAAA;AAGF;;AAAA;EACE,yBAAA;EACA,YAAA;AAGF;AAFE;EACE,YAAA;AAIJ;;AADC;EACC,sBAAA;EACA,YAAA;AAIF;;AADA;EACE,8BAAA;AAIF","sourcesContent":["table {\n  font-size: .9em;\n}\nbody .table .table-tbody > tr:nth-child(even) {\n  background-color: white;\n}\n.highlight {\n  background-color: #f9f9f9;\n}\n\ntr:hover {\n  background-color: #330aea;\n  color: white;\n  a{\n    color: white;\n  }\n}\n td,  th {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\n.non-scrollable-table .table-wrapper {\n  overflow-x: visible !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
