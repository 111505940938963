// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-bottom: 0.5em;
}

tr:hover {
  background-color: #330aea;
  color: white;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 10px;
}

.panel {
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #f5f7fa, #DBDCDD);
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/instances/instances.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;AACF;;AACC;EACC,sBAAA;EACA,YAAA;EACA,eAAA;AAEF;;AACA;EACE,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,yCAAA;EACA,qDAAA;AAEF","sourcesContent":[".container {\n  margin-bottom: 0.5em\n}\n\ntr:hover {\n  background-color: #330aea;\n  color: white;\n}\n td,  th {\n  border: 1px solid #ddd;\n  padding: 8px;\n  font-size: 10px;\n}\n\n.panel {\n  border: 1px solid #ddd;\n  border-radius: 12px;\n  padding: 20px;\n  box-shadow: 0 6px 12px rgba(0,0,0,0.1);\n  background: linear-gradient(135deg, #f5f7fa, #DBDCDD);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
