// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel {
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #f5f7fa, #DBDCDD);
}

.layout {
  font-weight: bold;
  padding-left: 50px;
  margin-bottom: 10px;
}

/* Style for the activity-info label */
.activity-info {
  font-style: italic;
  color: #777;
}

.btn-primary {
  background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.btn-primary:hover {
  background: linear-gradient(135deg, #0056b3, #003d7e); /* Darker gradient on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

.btn-primary:active {
  box-shadow: 0 2px #0056b3, 0 0 15px 3px rgba(0, 123, 255, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/pages/reporting/activity-report/activity-report.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,yCAAA;EACA,qDAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAGA,sCAAA;AACA;EACE,kBAAA;EACA,WAAA;AAAF;;AAGA;EACE,qDAAA,EAAA,wBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EAEA,iCAAA;EACA,eAAA;EACA,eAAA;EACA,2CAAA;AADF;;AAIA;EACE,qDAAA,EAAA,6BAAA;EACA,2BAAA,EAAA,yBAAA;AADF;;AAIA;EACE,8DAAA;AADF","sourcesContent":[".panel {\n  border: 1px solid #ddd;\n  border-radius: 12px;\n  padding: 20px;\n  box-shadow: 0 6px 12px rgba(0,0,0,0.1);\n  background: linear-gradient(135deg, #f5f7fa, #DBDCDD);\n}\n\n.layout{\n  font-weight: bold;\n  padding-left: 50px;\n  margin-bottom: 10px;\n}\n\n\n/* Style for the activity-info label */\n.activity-info {\n  font-style: italic;\n  color: #777;\n}\n\n.btn-primary {\n  background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n \n  font-family: 'Gotham', sans-serif;\n  font-size: 14px;\n  cursor: pointer;\n  transition: background 0.3s, transform 0.3s;\n}\n\n.btn-primary:hover {\n  background: linear-gradient(135deg, #0056b3, #003d7e); /* Darker gradient on hover */\n  transform: translateY(-2px); /* Lift effect on hover */\n}\n\n.btn-primary:active {\n  box-shadow: 0 2px #0056b3, 0 0 15px 3px rgba(0, 123, 255, 0.7);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
